import { createReducer, on } from '@ngrx/store';
import { featureFlagsActions } from '../actions/feature-flags.actions';
import { FeatureFlagsState, initialState } from '../models/feature-flags-state.model';

export const featureFlagsReducer = createReducer<FeatureFlagsState>(
  initialState,
  on(featureFlagsActions.startWatching, (state, { initialFlags }) => ({ ...state, flags: initialFlags, initialized: true })),
  on(featureFlagsActions.flagsChanged, (state, { flags }) => ({ ...state, flags: { ...state.flags, ...flags } })),
  on(featureFlagsActions.closed, () => initialState)
);
