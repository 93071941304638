import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxSkeletonLoaderConfigTheme, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule],
  selector: 'kp-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoaderComponent {
  @Input() count = 1;
  @Input() appearance: 'line' | 'circle' = 'line';
  @Input() isDark = false;
  @Input() theme?: NgxSkeletonLoaderConfigTheme;
}
