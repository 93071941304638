import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kpPrettySeconds',
  standalone: true,
})
export class PrettySecondsPipe implements PipeTransform {
  transform(value: number | null | undefined) {
    if (value == null) {
      return '';
    }
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}
