import type { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import type { ResultCode } from '@adyen/adyen-web/dist/types/components/types';

type RequiresAction = {
  action: PaymentAction;
};

interface BasePaymentResult {
  resultCode: ResultCode;
}

interface SuccessPaymentResult extends BasePaymentResult {
  resultCode: 'Authorised';
}

interface FailurePaymentResult extends BasePaymentResult {
  resultCode: Exclude<ResultCode, 'Authorised'>;
  refusalReason?: string;
}

export type AdyenPaymentResult = SuccessPaymentResult | FailurePaymentResult;
export type AdyenPaymentResponse = RequiresAction | AdyenPaymentResult;
