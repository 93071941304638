import { Injectable } from '@angular/core';
import { GrpcEvent, GrpcMessage, GrpcMetadata, GrpcRequest } from '@ngx-grpc/common';
import { GrpcHandler, GrpcInterceptor, GRPC_INTERCEPTORS } from '@ngx-grpc/core';
import { finalize, Observable } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Injectable()
export class GrpcLoadingBarInterceptor implements GrpcInterceptor {
  constructor(private loadingBarService: LoadingBarService) {}
  intercept<Q extends GrpcMessage, S extends GrpcMessage>(request: GrpcRequest<Q, S>, next: GrpcHandler): Observable<GrpcEvent<S>> {
    const ignoreLoadingBar = request.requestMetadata.has('ignoreLoadingBar');
    if (ignoreLoadingBar) {
      const metadataObj = request.requestMetadata.toObject() as { ignoreLoadingBar?: string };
      delete metadataObj['ignoreLoadingBar'];
      request.requestMetadata = new GrpcMetadata(metadataObj);
    } else {
      this.loadingBarService.useRef().start();
    }
    return next.handle(request).pipe(finalize(() => !ignoreLoadingBar && this.loadingBarService.useRef().complete()));
  }
}

export const loadingBarInterceptorProvider = [{ provide: GRPC_INTERCEPTORS, useClass: GrpcLoadingBarInterceptor, multi: true }];
