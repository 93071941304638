import { datadogRum } from '@datadog/browser-rum';
import { APP_INITIALIZER, ErrorHandler, InjectionToken } from '@angular/core';
import { DatadogErrorHandlerService } from '../../services/datadog-error-handler/datadog-error-handler.service';
import { DatadogErrorMonitoringConfig } from '../../../types/datadog-error-monitoring.config';

export const datadogReportingInitializerProviderFactory = (config: DatadogErrorMonitoringConfig) => {
  return () => {
    datadogRum.init({
      applicationId: config.applicationId as string,
      clientToken: config.clientToken,
      site: config.site,
      version: config.version,
      env: config.environmentName,
      service: config.service,

      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  };
};

export const DATADOG_CONFIG = new InjectionToken<DatadogErrorMonitoringConfig>('Datadog configuration');

const DATADOG_REPORTING_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: datadogReportingInitializerProviderFactory,
  deps: [DATADOG_CONFIG],
  multi: true,
};

export const datadogErrorHandlingProviders = [
  DATADOG_REPORTING_INITIALIZER,
  {
    provide: ErrorHandler,
    useClass: DatadogErrorHandlerService,
  },
];
