import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PaymentFacadeService } from '../../+state/services/payment-facade.service';
import { paymentActions } from '../../+state/actions/payment.actions';

@Injectable({
  providedIn: 'root',
})
export class LinkDetailsGuard  {
  constructor(private paymentFacadeService: PaymentFacadeService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const linkId = route.paramMap.get('linkId');
    if (!linkId) return false;

    this.paymentFacadeService.dispatch(paymentActions.openLink({ linkId }));
    return true;
  }
}
