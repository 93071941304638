import { Injectable } from '@angular/core';
import { PaymentFacadeService } from '../../+state/services/payment-facade.service';
import { filter, map } from 'rxjs';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LinkResult } from '../../types/link-result.type';

@Injectable({
  providedIn: 'root',
})
export class LinkStatusGuard  {
  constructor(private paymentFacadeService: PaymentFacadeService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const linkId = route.paramMap.get('linkId');
    if (!linkId) return false;

    return this.paymentFacadeService.linkDetails$().pipe(
      filter(Boolean),
      map(({ status }) => {
        if (status === 'active') return true; // Let the user continue to pay the link

        const result: LinkResult = status === 'completed' ? 'already-paid' : 'finished';
        return this.router.createUrlTree([`${linkId}/${result}`]);
      })
    );
  }
}
