import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CountdownComponent } from '@libs/shared/ui/shared/countdown/countdown.component';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { faClockEight } from '@fortawesome/pro-solid-svg-icons/faClockEight';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'kp-payment-countdown',
  standalone: true,
  imports: [CountdownComponent, FontAwesomeModule, TranslateModule],
  templateUrl: './payment-countdown.component.html',
  styleUrls: ['./payment-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCountdownComponent {
  @Input() set expiryDate(t: Timestamp.AsObject | undefined) {
    if (t) {
      const now = new Date().getTime();
      const until = new Timestamp(t).toDate().getTime();
      this.secondsRemaining = Math.floor((until - now) / 1000);
    }
  }
  @Output() handleExpiry = new EventEmitter<void>();

  readonly icons = { faClockEight };

  secondsRemaining: number;
}
