/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
export enum Currency {
  GBP = 0,
  EUR = 1,
  USD = 2,
  HKD = 3
}
/**
 * Message implementation for com.kodypay.grpc.common.Money
 */
export class Money implements GrpcMessage {
  static id = 'com.kodypay.grpc.common.Money';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Money();
    Money.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Money) {
    _instance.value = _instance.value || '';
    _instance.currency = _instance.currency || 0;
    _instance.currencyCode = _instance.currencyCode || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Money, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.value = _reader.readString();
          break;
        case 2:
          _instance.currency = _reader.readEnum();
          break;
        case 3:
          _instance.currencyCode = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Money.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Money, _writer: BinaryWriter) {
    if (_instance.value) {
      _writer.writeString(1, _instance.value);
    }
    if (_instance.currency) {
      _writer.writeEnum(2, _instance.currency);
    }
    if (_instance.currencyCode) {
      _writer.writeString(3, _instance.currencyCode);
    }
  }

  private _value: string;
  private _currency: Currency;
  private _currencyCode: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Money to deeply clone from
   */
  constructor(_value?: RecursivePartial<Money.AsObject>) {
    _value = _value || {};
    this.value = _value.value;
    this.currency = _value.currency;
    this.currencyCode = _value.currencyCode;
    Money.refineValues(this);
  }
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
  }
  get currency(): Currency {
    return this._currency;
  }
  set currency(value: Currency) {
    this._currency = value;
  }
  get currencyCode(): string {
    return this._currencyCode;
  }
  set currencyCode(value: string) {
    this._currencyCode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Money.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Money.AsObject {
    return {
      value: this.value,
      currency: this.currency,
      currencyCode: this.currencyCode
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Money.AsProtobufJSON {
    return {
      value: this.value,
      currency:
        Currency[
          this.currency === null || this.currency === undefined
            ? 0
            : this.currency
        ],
      currencyCode: this.currencyCode
    };
  }
}
export module Money {
  /**
   * Standard JavaScript object representation for Money
   */
  export interface AsObject {
    value: string;
    currency: Currency;
    currencyCode: string;
  }

  /**
   * Protobuf JSON representation for Money
   */
  export interface AsProtobufJSON {
    value: string;
    currency: string;
    currencyCode: string;
  }
}
