import { ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

export function shopperInformationSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const metaReducer = localStorageSync({
    keys: [{ payment: ['shopperInformation'] }],
    rehydrate: true,
    storage: window.sessionStorage,
  });

  return metaReducer(reducer);
}
