import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DevelopmentService {
  isDevMode() {
    return isDevMode();
  }
}
