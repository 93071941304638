export type PaymentOutcome = 'success' | 'failed';

interface BasePaymentResult {
  outcome: PaymentOutcome;
}

interface SuccessPaymentResult extends BasePaymentResult {
  outcome: 'success';
}

interface FailedPaymentResult extends BasePaymentResult {
  outcome: 'failed';
  failureReason?: string;
}

export type PaymentResult = SuccessPaymentResult | FailedPaymentResult;
