/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
export enum AcceptedPaymentMethodGroup {
  STANDARD_CARDS = 0,
  AMEX = 1,
  E_WALLETS = 2
}
export enum LinkType {
  MULTIPLE = 0,
  SINGLE = 1,
  SINGLE_DEPOSIT = 2,
  MULTIPLE_DEPOSIT = 3
}
