import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PAGE_TITLE_SEPARATOR } from '../../tokens/page-title-separator.token';

// Angular title strategy which recursively traverses the route tree to build the title
// Separating each page title with a separator

@Injectable({
  providedIn: 'root',
})
export class RecursiveTitleStrategy extends TitleStrategy {
  private readonly maxTitleSegments = 2;

  constructor(private title: Title, @Inject(PAGE_TITLE_SEPARATOR) private separator: string) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    const collectedTitles = this.collectTitles(snapshot.root);
    const chosenTitles = collectedTitles.slice(0, this.maxTitleSegments); // Take first n segments
    const title = chosenTitles.join(this.separator);

    this.title.setTitle(title);
  }

  private collectTitles(snapshot: ActivatedRouteSnapshot | null): string[] {
    if (snapshot === null) {
      return [];
    }

    const title = this.getResolvedTitleForRoute(snapshot);

    if (title) {
      return [...this.collectTitles(snapshot.firstChild), title];
    }
    return this.collectTitles(snapshot.firstChild);
  }
}
