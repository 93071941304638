import { createAction, props } from '@ngrx/store';
import { FeatureFlags } from '../../types/feature-flags.type';
import { LDContextCommon } from 'launchdarkly-js-sdk-common';

const initialize = createAction('[Feature Flags] Initialize', props<{ context: LDContextCommon }>());
const startWatching = createAction('[Feature Flags] Start watching', props<{ initialFlags: FeatureFlags }>());
const flagsChanged = createAction('[Feature Flags] Flags Changed', props<{ flags: FeatureFlags }>());
const close = createAction('[Feature Flags] Close');
const closed = createAction('[Feature Flags] Closed');

export const featureFlagsActions = {
  initialize,
  startWatching,
  flagsChanged,
  close,
  closed,
};
