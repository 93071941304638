@if (secondsRemaining > 0) {
  <div>
    <fa-icon [icon]="icons.faClockEight"></fa-icon>
    <span>
      <span> {{ 'paymentExpiry.prefix' | translate }} </span>
      <kp-countdown [labels]="'time' | translate" [seconds]="{ value: secondsRemaining }" (completed)="handleExpiry.emit()"></kp-countdown>
      <span> {{ 'paymentExpiry.suffix' | translate }} </span>
    </span>
  </div>
}
