import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import { Action, Creator, TypedAction } from '@ngrx/store/src/models';
import { Observable, filter, switchMap, takeUntil, tap } from 'rxjs';
import { AppNotifications } from '../notification/notifications.service';
import { APP_NOTIFICATIONS } from '../../tokens/notifications.token';

@Injectable({ providedIn: 'root' })
export class EffectsHelperService {
  constructor(private actions$: Actions, @Inject(APP_NOTIFICATIONS) private appNotifications: AppNotifications) {}

  createNotificationEffect(
    errorAction: ActionCreator<string, Creator<any[], TypedAction<string> & { message: string; skipNotification?: boolean }>>,
    successAction?: ActionCreator<string, Creator<any[], TypedAction<string> & { message: string; skipNotification?: boolean }>>
  ) {
    const actionTypes = successAction ? [errorAction, successAction] : [errorAction];
    return createEffect(
      () => {
        return this.actions$.pipe(
          ofType(...actionTypes),
          filter((action) => action.skipNotification !== true),
          tap((action) => {
            const isErrorAction = action.type === errorAction.type;
            isErrorAction ? this.appNotifications.error(action.message) : this.appNotifications.success(action.message);
          })
        );
      },
      { dispatch: false }
    );
  }

  /**
   * Listen for actions only while a specific page is open (based on its enter/leave page actions being fired)
   */
  whileOnPage = <B extends object>(
    actions$: Actions<Action>,
    enterPage: ActionCreator,
    leavePage: ActionCreator,
    actionsToObserve: ActionCreator<string, Creator<any[], B>>[]
  ): Observable<B> =>
    actions$.pipe(
      ofType(enterPage),
      switchMap(() => {
        return actions$.pipe(ofType(...actionsToObserve), takeUntil(actions$.pipe(ofType(leavePage))));
      })
    );
}
