import { Component, ElementRef, ErrorHandler, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SkeletonLoaderComponent } from '@libs/shared/ui/shared/skeleton-loader/skeleton-loader.component';
import { AdyenPaymentService } from '../../services/adyen-payment-service/adyen-payment.service';
import type { PaymentResult } from '../../types/payment-result.type';
import type DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import { LoaderService } from '@libs/shared/utilities/services/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { DropinElementProps } from '@adyen/adyen-web/dist/types/components/Dropin/types';

@Component({
  selector: 'kp-payment-form',
  standalone: true,
  imports: [SkeletonLoaderComponent],
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
})
export class PaymentFormComponent implements OnInit, OnDestroy {
  @ViewChild('hook', { static: true }) hook: ElementRef;

  @Input() currency: string;
  @Input() cents: number;

  @Output() paymentResult = new EventEmitter<PaymentResult>();

  private onDestroy$ = new Subject<null>();
  private styleElement: HTMLElement;
  private dropinElement?: DropinElement;
  loaded = false;

  constructor(
    private adyenPaymentService: AdyenPaymentService,
    private loaderService: LoaderService,
    private errorHandler: ErrorHandler,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    // Inject the card details style
    this.styleElement = this.loaderService.injectStyle(this.adyenPaymentService.style);
    this.translateService.onLangChange
      .pipe(
        filter(() => !!this.dropinElement),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => {
        this.dropinElement?.unmount();
        this.loaded = false;
        this.initiatePayment(this.onDestroy$);
      });
    await this.initiatePayment(this.onDestroy$);
  }

  async initiatePayment(until$: Subject<any>): Promise<void> {
    try {
      const { checkout, paymentResult } = await this.adyenPaymentService.initiatePayment(
        this.currency,
        this.cents,
        this.mapLanguage(this.translateService.currentLang),
        until$,
      );
      this.dropinElement = checkout
        .create('dropin', {
          instantPaymentTypes: ['googlepay', 'applepay'],
          onReady: () => (this.loaded = true),
        })
        .mount(this.hook.nativeElement);
      (this.dropinElement as unknown as DropinElementProps)?.onReady?.();
      this.paymentResult.emit(await paymentResult);
    } catch (error) {
      this.loaded = true;
      this.errorHandler.handleError(error);
    }
  }

  mapLanguage(languageCode: string): string {
    return languageCode === 'zh' ? 'zh_TW' : languageCode;
  }

  ngOnDestroy(): void {
    // Clean up the injected style element
    this.loaderService.remove(this.styleElement);

    // Clean up the checkout element
    this.dropinElement?.unmount();

    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
