import { ErrorHandler, Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { DevelopmentService } from '@libs/shared/utilities/services/development/development.service';

@Injectable({
  providedIn: 'root',
})
export class DatadogErrorHandlerService extends ErrorHandler {
  constructor(private developmentService: DevelopmentService) {
    super();
  }

  override handleError(error: string | Error | unknown) {
    if (this.developmentService.isDevMode()) {
      console.error(error);
    }

    datadogRum.addError(error);
  }
}
