import { createFeature, provideState } from '@ngrx/store';
import { paymentReducer } from './reducers/payment.reducer';
import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { PaymentEffects } from './effects/payment.effects';

export function providePaymentFeature(): EnvironmentProviders[] {
  return [provideState(paymentFeature), provideEffects([PaymentEffects])];
}

export const paymentFeature = createFeature({
  name: 'payment',
  reducer: paymentReducer,
});
