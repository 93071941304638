import { Injectable } from '@angular/core';
import { NotificationsService } from '../notifications.service';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NativeNotificationsService extends NotificationsService {
  constructor(private toastController: ToastController) {
    super();
  }

  private create(message: string, type: 'success' | 'error', options?: Partial<ToastOptions>): Promise<HTMLIonToastElement> {
    return this.toastController.create({
      message,
      duration: 2500,
      position: 'top',
      cssClass: `native-notification native-notification__${type}`,
      ...options,
    });
  }

  override async success(message: string): Promise<void> {
    const toast = await this.create(message, 'success', { color: 'success' });
    await toast.present();
  }

  override async error(message: string): Promise<void> {
    const toast = await this.create(message, 'error');
    await toast.present();
  }
  override dismiss(): void {
    this.toastController.dismiss();
  }
}
