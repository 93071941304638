import { EnvironmentProviders, NgModule } from '@angular/core';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { createFeature, provideState, StoreModule } from '@ngrx/store';
import { FeatureFlagsEffects } from './effects/feature-flags.effects';
import { featureFlagsStoreKey } from './models/feature-flags-state.model';
import { featureFlagsReducer } from './reducers/feature-flags.reducer';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(featureFlagsStoreKey, featureFlagsReducer), EffectsModule.forFeature([FeatureFlagsEffects])],
})
export class FeatureFlagsStateModule {}

export function provideFeatureFlags(): EnvironmentProviders[] {
  return [
    provideState(createFeature({ name: featureFlagsStoreKey, reducer: featureFlagsReducer })),
    provideEffects([FeatureFlagsEffects]),
  ];
}
