import { NgPlural, NgPluralCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PrettySecondsPipe } from '@libs/utility-date-time/pipes/pretty-seconds/pretty-seconds.pipe';
import { clearInterval, setInterval } from 'worker-timers';

@Component({
  standalone: true,
  imports: [PrettySecondsPipe, NgPlural, NgPluralCase],
  selector: 'kp-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnChanges, OnDestroy {
  constructor(private cdr: ChangeDetectorRef) {}
  @Input() seconds?: { value: number | string; date?: Date } | null; // date used to help detecting changes
  @Input() labels: Record<string, string> = {
    hours: 'hours',
    hour: 'hour',
    days: 'days',
    day: 'day',
  };
  @Output() completed = new EventEmitter<void>();

  readonly Math = Math;
  secondsRemaining = 0;
  private intervalId: number | null = null;

  startTimer(seconds: number): void {
    this.secondsRemaining = seconds;
    this.intervalId = setInterval(() => {
      this.secondsRemaining--;
      this.cdr.detectChanges();
      if (this.secondsRemaining <= 0) {
        this.completed.emit();
        this.resetTimer();
      }
    }, 1000);
  }

  resetTimer(): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  ngOnChanges({ seconds }: SimpleChanges): void {
    if (seconds && seconds.currentValue && seconds.currentValue.value > 0) {
      this.resetTimer();
      this.startTimer(seconds.currentValue.value);
    }
  }

  ngOnDestroy(): void {
    this.resetTimer();
  }
}
