import type { PaymentMethodsResponse } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';
import type { AdyenPaymentResponse } from '../types/adyen-payment-response.interface';
import { Subject } from 'rxjs';

export abstract class AdyenPaymentApiService {
  abstract getPaymentMethods(until$: Subject<any>): Promise<PaymentMethodsResponse>;

  abstract makePayment(paymentMethod: unknown, browserInfo: unknown, paymentBrand: string | undefined): Promise<AdyenPaymentResponse>;

  abstract submitAdditionalDetails(details: unknown): Promise<AdyenPaymentResponse>;

  abstract applePayValidateMerchant(): Promise<unknown>;

  abstract merchantDisplayName(): Promise<string>;

  abstract gatewayMerchantId(): Promise<string>;
}
