import { FeatureFlags } from '../../types/feature-flags.type';
export const featureFlagsStoreKey = 'featureFlags';

export interface FeatureFlagsState {
  initialized: boolean;
  flags: FeatureFlags;
}

export const initialState: FeatureFlagsState = {
  initialized: false,
  flags: {},
};
