<span class="countdown">
  @if ({ days: Math.floor(secondsRemaining / 86400), hours: Math.floor(secondsRemaining / 3600) }; as units) {
    @if (units.days >= 1) {
      {{ units.days }}
      <span [ngPlural]="units.days">
        <ng-template ngPluralCase="=1">{{ labels['day'] }}</ng-template>
        <ng-template ngPluralCase="other">{{ labels['days'] }}</ng-template>
      </span>
    } @else if (units.hours >= 1) {
      {{ units.hours }}
      <span [ngPlural]="units.hours">
        <ng-template ngPluralCase="=1">{{ labels['hour'] }}</ng-template>
        <ng-template ngPluralCase="other">{{ labels['hours'] }}</ng-template>
      </span>
    } @else {
      <span class="fixed-width">{{ secondsRemaining | kpPrettySeconds }}</span>
    }
  }
</span>
