import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrpcWebClientModule } from '@ngx-grpc/grpc-web-client';
import { GrpcCoreModule } from '@ngx-grpc/core';
import { loadingBarInterceptorProvider } from './interceptors/grpc-loading-bar.interceptor';

@NgModule({
  imports: [CommonModule, GrpcCoreModule.forRoot(), GrpcWebClientModule.forRoot()],
  providers: [loadingBarInterceptorProvider],
})
export class SharedGrpcModule {}
