import { PaymentMethodsResponse } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';
import { Inject, Injectable } from '@angular/core';
import type { AdyenPaymentResponse } from '@libs/feature-payments';
import { AdyenPaymentApiService } from '@libs/feature-payments';
import { LinkApiService } from '../link-api/link-api.service';
import { Subject, combineLatestWith, filter, firstValueFrom, map, switchMap } from 'rxjs';
import { PaymentFacadeService } from '../../+state/services/payment-facade.service';
import { WINDOW } from '@libs/shared/utilities/tokens/window.token';

@Injectable({
  providedIn: 'root',
})
export class LinkPaymentApiService implements AdyenPaymentApiService {
  constructor(
    private linkApiService: LinkApiService,
    private paymentFacadeService: PaymentFacadeService,
    @Inject(WINDOW) private window: Window,
  ) {}

  getPaymentMethods(until$: Subject<any>): Promise<PaymentMethodsResponse> {
    return firstValueFrom(
      this.paymentFacadeService.linkId$().pipe(
        filter(Boolean),
        switchMap((linkId) => this.linkApiService.getPaymentMethods(linkId, until$)),
      ),
    );
  }

  makePayment(paymentMethod: unknown, browserInfo: unknown, paymentBrand: string | undefined): Promise<AdyenPaymentResponse> {
    return firstValueFrom(
      this.paymentFacadeService.linkId$().pipe(
        filter(Boolean),
        combineLatestWith(this.paymentFacadeService.shopperInformation$().pipe(filter(Boolean))),
        switchMap(([linkId, shopperInformation]) =>
          this.linkApiService.makePayment(
            linkId,
            paymentMethod,
            browserInfo,
            this.window.location.origin,
            shopperInformation,
            paymentBrand,
          ),
        ),
      ),
    );
  }

  submitAdditionalDetails(details: unknown): Promise<AdyenPaymentResponse> {
    return firstValueFrom(
      this.paymentFacadeService.linkId$().pipe(
        filter(Boolean),
        switchMap((linkId) => this.linkApiService.submitAdditionalDetails(linkId, details)),
      ),
    );
  }

  applePayValidateMerchant(): Promise<unknown> {
    return firstValueFrom(
      this.paymentFacadeService.linkId$().pipe(
        filter(Boolean),
        switchMap((linkId) => this.linkApiService.createApplePaySession(linkId)),
      ),
    );
  }

  merchantDisplayName(): Promise<string> {
    return firstValueFrom(
      this.paymentFacadeService.linkDetails$().pipe(
        filter(Boolean),
        map((link) => link.storeName),
      ),
    );
  }

  gatewayMerchantId(): Promise<string> {
    return firstValueFrom(
      this.paymentFacadeService.linkDetails$().pipe(
        filter(Boolean),
        map((link) => link.gatewayMerchantId),
      ),
    );
  }
}
