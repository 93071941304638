import { Injectable } from '@angular/core';

import { filter, map, Observable } from 'rxjs';
import { PaymentFacadeService } from '../../+state/services/payment-facade.service';

@Injectable({
  providedIn: 'root',
})
export class LinkDescriptionResolver  {
  constructor(private paymentFacadeService: PaymentFacadeService) {}

  resolve(): Observable<string> {
    return this.paymentFacadeService.linkDetails$().pipe(
      filter(Boolean),
      map((linkDetails) => linkDetails.description)
    );
  }
}
