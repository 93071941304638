import { Actions, ofType } from '@ngrx/effects';
import { createAction } from '@ngrx/store';
import { Observable, SchedulerLike, Subject, catchError, switchMap, takeUntil, throwError, timer } from 'rxjs';

export function retryWithDelay<T>(maxRetry: number, delayMs: number, until$: Actions | Subject<any>, scheduler?: SchedulerLike) {
  let retries = 0;
  return (source: Observable<T>) => {
    return source.pipe(
      catchError((error, caught) => {
        if (retries >= maxRetry) {
          return throwError(() => error);
        }
        retries++;
        return timer(delayMs, scheduler).pipe(switchMap(() => caught));
      }),
      takeUntil(until$ instanceof Actions ? until$.pipe(ofType(cancelRetry)) : until$),
    );
  };
}

// To avoid circular dependency between the auth module.
// The auth module will dispatch this action when the user is logged out
export const cancelRetry = createAction(`GRPC Cancel retry`);
