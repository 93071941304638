import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Environment } from '@libs/core/types';
import { APP_ENVIRONMENT } from '../tokens/app-environment.token';
import { PAGE_TITLE_SEPARATOR } from '../tokens/page-title-separator.token';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  constructor(
    private title: Title,
    @Inject(APP_ENVIRONMENT) private environment: Environment,
    @Inject(PAGE_TITLE_SEPARATOR) private pageTitleSeparator: string
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const pageTitle = this.buildTitle(routerState);
    this.title.setTitle(`${pageTitle ? `${pageTitle}${this.pageTitleSeparator}` : ''}${this.environment.appTitle}`);
  }
}
