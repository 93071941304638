import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { featureFlagsSelectors } from '../../+state/selectors/feature-flags.selectors';
import { FeatureFlagValue } from '../../types/feature-flag-value.type';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagFacadeService {
  constructor(private store: Store) {}

  isActive = (key: string, expectedValue: FeatureFlagValue = true) => this.store.select(featureFlagsSelectors.isActive(key, expectedValue));
  initialized = () => this.store.select(featureFlagsSelectors.initialized);

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}
