@if (languageCode) {
  <div>
    <fa-icon [icon]="icons.faLanguage" class="language"></fa-icon>
    <select [ngModel]="languageCode" (ngModelChange)="handleChange($event)">
      @for (language of languageEntries; track language.code) {
        <option [value]="language.code">{{ language.name }}</option>
      }
    </select>
    <fa-icon [icon]="icons.faAngleDown" class="arrow"></fa-icon>
  </div>
}
