import { createActionGroup, props } from '@ngrx/store';
import { LinkDetails } from '../../types/link-details.type';

export const paymentApiActions = createActionGroup({
  source: 'Payment API',
  events: {
    'Link details loaded success': props<{ linkDetails: LinkDetails }>(),
    'Link details loaded error': props<{ error: string }>(),
  },
});
