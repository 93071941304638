/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as comKodypayGrpcCommon000 from '../../../../../com/kodypay/grpc/common/money.pb';
import * as comKodypayGrpcWombatV1001 from '../../../../../com/kodypay/grpc/wombat/v1/common.pb';
import * as comKodypayGrpcCommon002 from '../../../../../com/kodypay/grpc/common/pay-by-link.pb';
import * as googleProtobuf003 from '@ngx-grpc/well-known-types';
/**
 * Message implementation for com.kodypay.grpc.wombat.v1.GetLinkRequest
 */
export class GetLinkRequest implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.GetLinkRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLinkRequest();
    GetLinkRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLinkRequest) {
    _instance.linkId = _instance.linkId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLinkRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.linkId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetLinkRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLinkRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.linkId) {
      _writer.writeString(1, _instance.linkId);
    }
  }

  private _linkId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLinkRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLinkRequest.AsObject>) {
    _value = _value || {};
    this.linkId = _value.linkId;
    GetLinkRequest.refineValues(this);
  }
  get linkId(): string {
    return this._linkId;
  }
  set linkId(value: string) {
    this._linkId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLinkRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLinkRequest.AsObject {
    return {
      linkId: this.linkId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLinkRequest.AsProtobufJSON {
    return {
      linkId: this.linkId
    };
  }
}
export module GetLinkRequest {
  /**
   * Standard JavaScript object representation for GetLinkRequest
   */
  export interface AsObject {
    linkId: string;
  }

  /**
   * Protobuf JSON representation for GetLinkRequest
   */
  export interface AsProtobufJSON {
    linkId: string;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.GetLinkResponse
 */
export class GetLinkResponse implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.GetLinkResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLinkResponse();
    GetLinkResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLinkResponse) {
    _instance.type = _instance.type || 0;
    _instance.status = _instance.status || 0;
    _instance.amount = _instance.amount || undefined;
    _instance.description = _instance.description || '';
    _instance.prompts = _instance.prompts || [];
    _instance.storeName = _instance.storeName || '';
    _instance.defaultLanguage = _instance.defaultLanguage || '';
    _instance.supportedLanguages = _instance.supportedLanguages || [];
    _instance.countryCode = _instance.countryCode || '';
    _instance.acceptedPaymentMethodGroups =
      _instance.acceptedPaymentMethodGroups || [];
    _instance.gatewayMerchantId = _instance.gatewayMerchantId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLinkResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.status = _reader.readEnum();
          break;
        case 3:
          _instance.amount = new comKodypayGrpcCommon000.Money();
          _reader.readMessage(
            _instance.amount,
            comKodypayGrpcCommon000.Money.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.description = _reader.readString();
          break;
        case 5:
          (_instance.prompts = _instance.prompts || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 7:
          _instance.storeName = _reader.readString();
          break;
        case 8:
          _instance.defaultLanguage = _reader.readString();
          break;
        case 9:
          (_instance.supportedLanguages =
            _instance.supportedLanguages || []).push(_reader.readString());
          break;
        case 10:
          _instance.countryCode = _reader.readString();
          break;
        case 11:
          (_instance.acceptedPaymentMethodGroups =
            _instance.acceptedPaymentMethodGroups || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 12:
          _instance.gatewayMerchantId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetLinkResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLinkResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.status) {
      _writer.writeEnum(2, _instance.status);
    }
    if (_instance.amount) {
      _writer.writeMessage(
        3,
        _instance.amount as any,
        comKodypayGrpcCommon000.Money.serializeBinaryToWriter
      );
    }
    if (_instance.description) {
      _writer.writeString(4, _instance.description);
    }
    if (_instance.prompts && _instance.prompts.length) {
      _writer.writePackedEnum(5, _instance.prompts);
    }
    if (_instance.storeName) {
      _writer.writeString(7, _instance.storeName);
    }
    if (_instance.defaultLanguage) {
      _writer.writeString(8, _instance.defaultLanguage);
    }
    if (_instance.supportedLanguages && _instance.supportedLanguages.length) {
      _writer.writeRepeatedString(9, _instance.supportedLanguages);
    }
    if (_instance.countryCode) {
      _writer.writeString(10, _instance.countryCode);
    }
    if (
      _instance.acceptedPaymentMethodGroups &&
      _instance.acceptedPaymentMethodGroups.length
    ) {
      _writer.writePackedEnum(11, _instance.acceptedPaymentMethodGroups);
    }
    if (_instance.gatewayMerchantId) {
      _writer.writeString(12, _instance.gatewayMerchantId);
    }
  }

  private _type: comKodypayGrpcCommon002.LinkType;
  private _status: comKodypayGrpcWombatV1001.LinkStatus;
  private _amount?: comKodypayGrpcCommon000.Money;
  private _description: string;
  private _prompts: comKodypayGrpcWombatV1001.LinkPrompt[];
  private _storeName: string;
  private _defaultLanguage: string;
  private _supportedLanguages: string[];
  private _countryCode: string;
  private _acceptedPaymentMethodGroups: comKodypayGrpcCommon002.AcceptedPaymentMethodGroup[];
  private _gatewayMerchantId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLinkResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLinkResponse.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.status = _value.status;
    this.amount = _value.amount
      ? new comKodypayGrpcCommon000.Money(_value.amount)
      : undefined;
    this.description = _value.description;
    this.prompts = (_value.prompts || []).slice();
    this.storeName = _value.storeName;
    this.defaultLanguage = _value.defaultLanguage;
    this.supportedLanguages = (_value.supportedLanguages || []).slice();
    this.countryCode = _value.countryCode;
    this.acceptedPaymentMethodGroups = (
      _value.acceptedPaymentMethodGroups || []
    ).slice();
    this.gatewayMerchantId = _value.gatewayMerchantId;
    GetLinkResponse.refineValues(this);
  }
  get type(): comKodypayGrpcCommon002.LinkType {
    return this._type;
  }
  set type(value: comKodypayGrpcCommon002.LinkType) {
    this._type = value;
  }
  get status(): comKodypayGrpcWombatV1001.LinkStatus {
    return this._status;
  }
  set status(value: comKodypayGrpcWombatV1001.LinkStatus) {
    this._status = value;
  }
  get amount(): comKodypayGrpcCommon000.Money | undefined {
    return this._amount;
  }
  set amount(value: comKodypayGrpcCommon000.Money | undefined) {
    this._amount = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get prompts(): comKodypayGrpcWombatV1001.LinkPrompt[] {
    return this._prompts;
  }
  set prompts(value: comKodypayGrpcWombatV1001.LinkPrompt[]) {
    this._prompts = value;
  }
  get storeName(): string {
    return this._storeName;
  }
  set storeName(value: string) {
    this._storeName = value;
  }
  get defaultLanguage(): string {
    return this._defaultLanguage;
  }
  set defaultLanguage(value: string) {
    this._defaultLanguage = value;
  }
  get supportedLanguages(): string[] {
    return this._supportedLanguages;
  }
  set supportedLanguages(value: string[]) {
    this._supportedLanguages = value;
  }
  get countryCode(): string {
    return this._countryCode;
  }
  set countryCode(value: string) {
    this._countryCode = value;
  }
  get acceptedPaymentMethodGroups(): comKodypayGrpcCommon002.AcceptedPaymentMethodGroup[] {
    return this._acceptedPaymentMethodGroups;
  }
  set acceptedPaymentMethodGroups(
    value: comKodypayGrpcCommon002.AcceptedPaymentMethodGroup[]
  ) {
    this._acceptedPaymentMethodGroups = value;
  }
  get gatewayMerchantId(): string {
    return this._gatewayMerchantId;
  }
  set gatewayMerchantId(value: string) {
    this._gatewayMerchantId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLinkResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLinkResponse.AsObject {
    return {
      type: this.type,
      status: this.status,
      amount: this.amount ? this.amount.toObject() : undefined,
      description: this.description,
      prompts: (this.prompts || []).slice(),
      storeName: this.storeName,
      defaultLanguage: this.defaultLanguage,
      supportedLanguages: (this.supportedLanguages || []).slice(),
      countryCode: this.countryCode,
      acceptedPaymentMethodGroups: (
        this.acceptedPaymentMethodGroups || []
      ).slice(),
      gatewayMerchantId: this.gatewayMerchantId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLinkResponse.AsProtobufJSON {
    return {
      type:
        comKodypayGrpcCommon002.LinkType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      status:
        comKodypayGrpcWombatV1001.LinkStatus[
          this.status === null || this.status === undefined ? 0 : this.status
        ],
      amount: this.amount ? this.amount.toProtobufJSON(options) : null,
      description: this.description,
      prompts: (this.prompts || []).map(
        v => comKodypayGrpcWombatV1001.LinkPrompt[v]
      ),
      storeName: this.storeName,
      defaultLanguage: this.defaultLanguage,
      supportedLanguages: (this.supportedLanguages || []).slice(),
      countryCode: this.countryCode,
      acceptedPaymentMethodGroups: (this.acceptedPaymentMethodGroups || []).map(
        v => comKodypayGrpcCommon002.AcceptedPaymentMethodGroup[v]
      ),
      gatewayMerchantId: this.gatewayMerchantId
    };
  }
}
export module GetLinkResponse {
  /**
   * Standard JavaScript object representation for GetLinkResponse
   */
  export interface AsObject {
    type: comKodypayGrpcCommon002.LinkType;
    status: comKodypayGrpcWombatV1001.LinkStatus;
    amount?: comKodypayGrpcCommon000.Money.AsObject;
    description: string;
    prompts: comKodypayGrpcWombatV1001.LinkPrompt[];
    storeName: string;
    defaultLanguage: string;
    supportedLanguages: string[];
    countryCode: string;
    acceptedPaymentMethodGroups: comKodypayGrpcCommon002.AcceptedPaymentMethodGroup[];
    gatewayMerchantId: string;
  }

  /**
   * Protobuf JSON representation for GetLinkResponse
   */
  export interface AsProtobufJSON {
    type: string;
    status: string;
    amount: comKodypayGrpcCommon000.Money.AsProtobufJSON | null;
    description: string;
    prompts: string[];
    storeName: string;
    defaultLanguage: string;
    supportedLanguages: string[];
    countryCode: string;
    acceptedPaymentMethodGroups: string[];
    gatewayMerchantId: string;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.GetIntegratedPaymentRequest
 */
export class GetIntegratedPaymentRequest implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.GetIntegratedPaymentRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetIntegratedPaymentRequest();
    GetIntegratedPaymentRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetIntegratedPaymentRequest) {
    _instance.paymentId = _instance.paymentId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetIntegratedPaymentRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.paymentId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetIntegratedPaymentRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetIntegratedPaymentRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.paymentId) {
      _writer.writeString(1, _instance.paymentId);
    }
  }

  private _paymentId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetIntegratedPaymentRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetIntegratedPaymentRequest.AsObject>) {
    _value = _value || {};
    this.paymentId = _value.paymentId;
    GetIntegratedPaymentRequest.refineValues(this);
  }
  get paymentId(): string {
    return this._paymentId;
  }
  set paymentId(value: string) {
    this._paymentId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetIntegratedPaymentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetIntegratedPaymentRequest.AsObject {
    return {
      paymentId: this.paymentId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetIntegratedPaymentRequest.AsProtobufJSON {
    return {
      paymentId: this.paymentId
    };
  }
}
export module GetIntegratedPaymentRequest {
  /**
   * Standard JavaScript object representation for GetIntegratedPaymentRequest
   */
  export interface AsObject {
    paymentId: string;
  }

  /**
   * Protobuf JSON representation for GetIntegratedPaymentRequest
   */
  export interface AsProtobufJSON {
    paymentId: string;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.GetIntegratedPaymentCardTokenRequest
 */
export class GetIntegratedPaymentCardTokenRequest implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.GetIntegratedPaymentCardTokenRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetIntegratedPaymentCardTokenRequest();
    GetIntegratedPaymentCardTokenRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetIntegratedPaymentCardTokenRequest) {
    _instance.paymentId = _instance.paymentId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetIntegratedPaymentCardTokenRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.paymentId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetIntegratedPaymentCardTokenRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetIntegratedPaymentCardTokenRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.paymentId) {
      _writer.writeString(1, _instance.paymentId);
    }
  }

  private _paymentId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetIntegratedPaymentCardTokenRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetIntegratedPaymentCardTokenRequest.AsObject>
  ) {
    _value = _value || {};
    this.paymentId = _value.paymentId;
    GetIntegratedPaymentCardTokenRequest.refineValues(this);
  }
  get paymentId(): string {
    return this._paymentId;
  }
  set paymentId(value: string) {
    this._paymentId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetIntegratedPaymentCardTokenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetIntegratedPaymentCardTokenRequest.AsObject {
    return {
      paymentId: this.paymentId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetIntegratedPaymentCardTokenRequest.AsProtobufJSON {
    return {
      paymentId: this.paymentId
    };
  }
}
export module GetIntegratedPaymentCardTokenRequest {
  /**
   * Standard JavaScript object representation for GetIntegratedPaymentCardTokenRequest
   */
  export interface AsObject {
    paymentId: string;
  }

  /**
   * Protobuf JSON representation for GetIntegratedPaymentCardTokenRequest
   */
  export interface AsProtobufJSON {
    paymentId: string;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.GetIntegratedPaymentCardTokenResponse
 */
export class GetIntegratedPaymentCardTokenResponse implements GrpcMessage {
  static id =
    'com.kodypay.grpc.wombat.v1.GetIntegratedPaymentCardTokenResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetIntegratedPaymentCardTokenResponse();
    GetIntegratedPaymentCardTokenResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetIntegratedPaymentCardTokenResponse) {
    _instance.token = _instance.token || '';
    _instance.expiry = _instance.expiry || '';
    _instance.status = _instance.status || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetIntegratedPaymentCardTokenResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.token = _reader.readString();
          break;
        case 2:
          _instance.expiry = _reader.readString();
          break;
        case 3:
          _instance.status = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    GetIntegratedPaymentCardTokenResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetIntegratedPaymentCardTokenResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.token) {
      _writer.writeString(1, _instance.token);
    }
    if (_instance.expiry) {
      _writer.writeString(2, _instance.expiry);
    }
    if (_instance.status) {
      _writer.writeEnum(3, _instance.status);
    }
  }

  private _token: string;
  private _expiry: string;
  private _status: GetIntegratedPaymentCardTokenResponse.CardTokenStatus;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetIntegratedPaymentCardTokenResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetIntegratedPaymentCardTokenResponse.AsObject>
  ) {
    _value = _value || {};
    this.token = _value.token;
    this.expiry = _value.expiry;
    this.status = _value.status;
    GetIntegratedPaymentCardTokenResponse.refineValues(this);
  }
  get token(): string {
    return this._token;
  }
  set token(value: string) {
    this._token = value;
  }
  get expiry(): string {
    return this._expiry;
  }
  set expiry(value: string) {
    this._expiry = value;
  }
  get status(): GetIntegratedPaymentCardTokenResponse.CardTokenStatus {
    return this._status;
  }
  set status(value: GetIntegratedPaymentCardTokenResponse.CardTokenStatus) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetIntegratedPaymentCardTokenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetIntegratedPaymentCardTokenResponse.AsObject {
    return {
      token: this.token,
      expiry: this.expiry,
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetIntegratedPaymentCardTokenResponse.AsProtobufJSON {
    return {
      token: this.token,
      expiry: this.expiry,
      status:
        GetIntegratedPaymentCardTokenResponse.CardTokenStatus[
          this.status === null || this.status === undefined ? 0 : this.status
        ]
    };
  }
}
export module GetIntegratedPaymentCardTokenResponse {
  /**
   * Standard JavaScript object representation for GetIntegratedPaymentCardTokenResponse
   */
  export interface AsObject {
    token: string;
    expiry: string;
    status: GetIntegratedPaymentCardTokenResponse.CardTokenStatus;
  }

  /**
   * Protobuf JSON representation for GetIntegratedPaymentCardTokenResponse
   */
  export interface AsProtobufJSON {
    token: string;
    expiry: string;
    status: string;
  }
  export enum CardTokenStatus {
    PENDING = 0,
    FAILED = 1,
    READY = 2
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.GetIntegratedPaymentResponse
 */
export class GetIntegratedPaymentResponse implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.GetIntegratedPaymentResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetIntegratedPaymentResponse();
    GetIntegratedPaymentResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetIntegratedPaymentResponse) {
    _instance.status = _instance.status || 0;
    _instance.amount = _instance.amount || undefined;
    _instance.description = _instance.description || '';
    _instance.storeName = _instance.storeName || '';
    _instance.defaultLanguage = _instance.defaultLanguage || '';
    _instance.supportedLanguages = _instance.supportedLanguages || [];
    _instance.countryCode = _instance.countryCode || '';
    _instance.returnUrl = _instance.returnUrl || '';
    _instance.cardTokenised = _instance.cardTokenised || false;
    _instance.gatewayMerchantId = _instance.gatewayMerchantId || '';
    _instance.expiryDate = _instance.expiryDate || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetIntegratedPaymentResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readEnum();
          break;
        case 2:
          _instance.amount = new comKodypayGrpcCommon000.Money();
          _reader.readMessage(
            _instance.amount,
            comKodypayGrpcCommon000.Money.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.storeName = _reader.readString();
          break;
        case 5:
          _instance.defaultLanguage = _reader.readString();
          break;
        case 6:
          (_instance.supportedLanguages =
            _instance.supportedLanguages || []).push(_reader.readString());
          break;
        case 7:
          _instance.countryCode = _reader.readString();
          break;
        case 8:
          _instance.returnUrl = _reader.readString();
          break;
        case 9:
          _instance.cardTokenised = _reader.readBool();
          break;
        case 10:
          _instance.gatewayMerchantId = _reader.readString();
          break;
        case 11:
          _instance.expiryDate = new googleProtobuf003.Timestamp();
          _reader.readMessage(
            _instance.expiryDate,
            googleProtobuf003.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetIntegratedPaymentResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetIntegratedPaymentResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeEnum(1, _instance.status);
    }
    if (_instance.amount) {
      _writer.writeMessage(
        2,
        _instance.amount as any,
        comKodypayGrpcCommon000.Money.serializeBinaryToWriter
      );
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.storeName) {
      _writer.writeString(4, _instance.storeName);
    }
    if (_instance.defaultLanguage) {
      _writer.writeString(5, _instance.defaultLanguage);
    }
    if (_instance.supportedLanguages && _instance.supportedLanguages.length) {
      _writer.writeRepeatedString(6, _instance.supportedLanguages);
    }
    if (_instance.countryCode) {
      _writer.writeString(7, _instance.countryCode);
    }
    if (_instance.returnUrl) {
      _writer.writeString(8, _instance.returnUrl);
    }
    if (_instance.cardTokenised) {
      _writer.writeBool(9, _instance.cardTokenised);
    }
    if (_instance.gatewayMerchantId) {
      _writer.writeString(10, _instance.gatewayMerchantId);
    }
    if (_instance.expiryDate) {
      _writer.writeMessage(
        11,
        _instance.expiryDate as any,
        googleProtobuf003.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _status: GetIntegratedPaymentResponse.IntegratedPaymentStatus;
  private _amount?: comKodypayGrpcCommon000.Money;
  private _description: string;
  private _storeName: string;
  private _defaultLanguage: string;
  private _supportedLanguages: string[];
  private _countryCode: string;
  private _returnUrl: string;
  private _cardTokenised: boolean;
  private _gatewayMerchantId: string;
  private _expiryDate?: googleProtobuf003.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetIntegratedPaymentResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetIntegratedPaymentResponse.AsObject>
  ) {
    _value = _value || {};
    this.status = _value.status;
    this.amount = _value.amount
      ? new comKodypayGrpcCommon000.Money(_value.amount)
      : undefined;
    this.description = _value.description;
    this.storeName = _value.storeName;
    this.defaultLanguage = _value.defaultLanguage;
    this.supportedLanguages = (_value.supportedLanguages || []).slice();
    this.countryCode = _value.countryCode;
    this.returnUrl = _value.returnUrl;
    this.cardTokenised = _value.cardTokenised;
    this.gatewayMerchantId = _value.gatewayMerchantId;
    this.expiryDate = _value.expiryDate
      ? new googleProtobuf003.Timestamp(_value.expiryDate)
      : undefined;
    GetIntegratedPaymentResponse.refineValues(this);
  }
  get status(): GetIntegratedPaymentResponse.IntegratedPaymentStatus {
    return this._status;
  }
  set status(value: GetIntegratedPaymentResponse.IntegratedPaymentStatus) {
    this._status = value;
  }
  get amount(): comKodypayGrpcCommon000.Money | undefined {
    return this._amount;
  }
  set amount(value: comKodypayGrpcCommon000.Money | undefined) {
    this._amount = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get storeName(): string {
    return this._storeName;
  }
  set storeName(value: string) {
    this._storeName = value;
  }
  get defaultLanguage(): string {
    return this._defaultLanguage;
  }
  set defaultLanguage(value: string) {
    this._defaultLanguage = value;
  }
  get supportedLanguages(): string[] {
    return this._supportedLanguages;
  }
  set supportedLanguages(value: string[]) {
    this._supportedLanguages = value;
  }
  get countryCode(): string {
    return this._countryCode;
  }
  set countryCode(value: string) {
    this._countryCode = value;
  }
  get returnUrl(): string {
    return this._returnUrl;
  }
  set returnUrl(value: string) {
    this._returnUrl = value;
  }
  get cardTokenised(): boolean {
    return this._cardTokenised;
  }
  set cardTokenised(value: boolean) {
    this._cardTokenised = value;
  }
  get gatewayMerchantId(): string {
    return this._gatewayMerchantId;
  }
  set gatewayMerchantId(value: string) {
    this._gatewayMerchantId = value;
  }
  get expiryDate(): googleProtobuf003.Timestamp | undefined {
    return this._expiryDate;
  }
  set expiryDate(value: googleProtobuf003.Timestamp | undefined) {
    this._expiryDate = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetIntegratedPaymentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetIntegratedPaymentResponse.AsObject {
    return {
      status: this.status,
      amount: this.amount ? this.amount.toObject() : undefined,
      description: this.description,
      storeName: this.storeName,
      defaultLanguage: this.defaultLanguage,
      supportedLanguages: (this.supportedLanguages || []).slice(),
      countryCode: this.countryCode,
      returnUrl: this.returnUrl,
      cardTokenised: this.cardTokenised,
      gatewayMerchantId: this.gatewayMerchantId,
      expiryDate: this.expiryDate ? this.expiryDate.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetIntegratedPaymentResponse.AsProtobufJSON {
    return {
      status:
        GetIntegratedPaymentResponse.IntegratedPaymentStatus[
          this.status === null || this.status === undefined ? 0 : this.status
        ],
      amount: this.amount ? this.amount.toProtobufJSON(options) : null,
      description: this.description,
      storeName: this.storeName,
      defaultLanguage: this.defaultLanguage,
      supportedLanguages: (this.supportedLanguages || []).slice(),
      countryCode: this.countryCode,
      returnUrl: this.returnUrl,
      cardTokenised: this.cardTokenised,
      gatewayMerchantId: this.gatewayMerchantId,
      expiryDate: this.expiryDate
        ? this.expiryDate.toProtobufJSON(options)
        : null
    };
  }
}
export module GetIntegratedPaymentResponse {
  /**
   * Standard JavaScript object representation for GetIntegratedPaymentResponse
   */
  export interface AsObject {
    status: GetIntegratedPaymentResponse.IntegratedPaymentStatus;
    amount?: comKodypayGrpcCommon000.Money.AsObject;
    description: string;
    storeName: string;
    defaultLanguage: string;
    supportedLanguages: string[];
    countryCode: string;
    returnUrl: string;
    cardTokenised: boolean;
    gatewayMerchantId: string;
    expiryDate?: googleProtobuf003.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetIntegratedPaymentResponse
   */
  export interface AsProtobufJSON {
    status: string;
    amount: comKodypayGrpcCommon000.Money.AsProtobufJSON | null;
    description: string;
    storeName: string;
    defaultLanguage: string;
    supportedLanguages: string[];
    countryCode: string;
    returnUrl: string;
    cardTokenised: boolean;
    gatewayMerchantId: string;
    expiryDate: googleProtobuf003.Timestamp.AsProtobufJSON | null;
  }
  export enum IntegratedPaymentStatus {
    Unpaid = 0,
    Paid = 1,
    Failed = 2,
    Expired = 3
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.GetPaymentMethodsRequest
 */
export class GetPaymentMethodsRequest implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.GetPaymentMethodsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetPaymentMethodsRequest();
    GetPaymentMethodsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetPaymentMethodsRequest) {
    _instance.linkId = _instance.linkId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetPaymentMethodsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.linkId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetPaymentMethodsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetPaymentMethodsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.linkId) {
      _writer.writeString(1, _instance.linkId);
    }
  }

  private _linkId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetPaymentMethodsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetPaymentMethodsRequest.AsObject>) {
    _value = _value || {};
    this.linkId = _value.linkId;
    GetPaymentMethodsRequest.refineValues(this);
  }
  get linkId(): string {
    return this._linkId;
  }
  set linkId(value: string) {
    this._linkId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetPaymentMethodsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetPaymentMethodsRequest.AsObject {
    return {
      linkId: this.linkId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetPaymentMethodsRequest.AsProtobufJSON {
    return {
      linkId: this.linkId
    };
  }
}
export module GetPaymentMethodsRequest {
  /**
   * Standard JavaScript object representation for GetPaymentMethodsRequest
   */
  export interface AsObject {
    linkId: string;
  }

  /**
   * Protobuf JSON representation for GetPaymentMethodsRequest
   */
  export interface AsProtobufJSON {
    linkId: string;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.GetPaymentMethodsResponse
 */
export class GetPaymentMethodsResponse implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.GetPaymentMethodsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetPaymentMethodsResponse();
    GetPaymentMethodsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetPaymentMethodsResponse) {
    _instance.paymentMethods = _instance.paymentMethods || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetPaymentMethodsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.paymentMethods = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetPaymentMethodsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetPaymentMethodsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.paymentMethods) {
      _writer.writeString(1, _instance.paymentMethods);
    }
  }

  private _paymentMethods: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetPaymentMethodsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetPaymentMethodsResponse.AsObject>) {
    _value = _value || {};
    this.paymentMethods = _value.paymentMethods;
    GetPaymentMethodsResponse.refineValues(this);
  }
  get paymentMethods(): string {
    return this._paymentMethods;
  }
  set paymentMethods(value: string) {
    this._paymentMethods = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetPaymentMethodsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetPaymentMethodsResponse.AsObject {
    return {
      paymentMethods: this.paymentMethods
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetPaymentMethodsResponse.AsProtobufJSON {
    return {
      paymentMethods: this.paymentMethods
    };
  }
}
export module GetPaymentMethodsResponse {
  /**
   * Standard JavaScript object representation for GetPaymentMethodsResponse
   */
  export interface AsObject {
    paymentMethods: string;
  }

  /**
   * Protobuf JSON representation for GetPaymentMethodsResponse
   */
  export interface AsProtobufJSON {
    paymentMethods: string;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.CreatePaymentSessionRequest
 */
export class CreatePaymentSessionRequest implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.CreatePaymentSessionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreatePaymentSessionRequest();
    CreatePaymentSessionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreatePaymentSessionRequest) {
    _instance.linkId = _instance.linkId || '';
    _instance.shopperFirstName = _instance.shopperFirstName || '';
    _instance.shopperLastName = _instance.shopperLastName || '';
    _instance.shopperPhone = _instance.shopperPhone || '';
    _instance.shopperEmail = _instance.shopperEmail || '';
    _instance.billingAddress = _instance.billingAddress || undefined;
    _instance.shippingAddress = _instance.shippingAddress || undefined;
    _instance.paymentMethodBrand = _instance.paymentMethodBrand || '';
    _instance.paymentMethodData = _instance.paymentMethodData || '';
    _instance.browserInfo = _instance.browserInfo || '';
    _instance.origin = _instance.origin || '';
    _instance.countryCode = _instance.countryCode || '';
    _instance.paymentMethodVariant = _instance.paymentMethodVariant || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreatePaymentSessionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.linkId = _reader.readString();
          break;
        case 2:
          _instance.shopperFirstName = _reader.readString();
          break;
        case 3:
          _instance.shopperLastName = _reader.readString();
          break;
        case 4:
          _instance.shopperPhone = _reader.readString();
          break;
        case 5:
          _instance.shopperEmail = _reader.readString();
          break;
        case 6:
          _instance.billingAddress = new CreatePaymentSessionRequest.Address();
          _reader.readMessage(
            _instance.billingAddress,
            CreatePaymentSessionRequest.Address.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.shippingAddress = new CreatePaymentSessionRequest.Address();
          _reader.readMessage(
            _instance.shippingAddress,
            CreatePaymentSessionRequest.Address.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.paymentMethodBrand = _reader.readString();
          break;
        case 9:
          _instance.paymentMethodData = _reader.readString();
          break;
        case 10:
          _instance.browserInfo = _reader.readString();
          break;
        case 11:
          _instance.origin = _reader.readString();
          break;
        case 12:
          _instance.countryCode = _reader.readString();
          break;
        case 13:
          _instance.paymentMethodVariant = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreatePaymentSessionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreatePaymentSessionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.linkId) {
      _writer.writeString(1, _instance.linkId);
    }
    if (_instance.shopperFirstName) {
      _writer.writeString(2, _instance.shopperFirstName);
    }
    if (_instance.shopperLastName) {
      _writer.writeString(3, _instance.shopperLastName);
    }
    if (_instance.shopperPhone) {
      _writer.writeString(4, _instance.shopperPhone);
    }
    if (_instance.shopperEmail) {
      _writer.writeString(5, _instance.shopperEmail);
    }
    if (_instance.billingAddress) {
      _writer.writeMessage(
        6,
        _instance.billingAddress as any,
        CreatePaymentSessionRequest.Address.serializeBinaryToWriter
      );
    }
    if (_instance.shippingAddress) {
      _writer.writeMessage(
        7,
        _instance.shippingAddress as any,
        CreatePaymentSessionRequest.Address.serializeBinaryToWriter
      );
    }
    if (_instance.paymentMethodBrand) {
      _writer.writeString(8, _instance.paymentMethodBrand);
    }
    if (_instance.paymentMethodData) {
      _writer.writeString(9, _instance.paymentMethodData);
    }
    if (_instance.browserInfo) {
      _writer.writeString(10, _instance.browserInfo);
    }
    if (_instance.origin) {
      _writer.writeString(11, _instance.origin);
    }
    if (_instance.countryCode) {
      _writer.writeString(12, _instance.countryCode);
    }
    if (_instance.paymentMethodVariant) {
      _writer.writeString(13, _instance.paymentMethodVariant);
    }
  }

  private _linkId: string;
  private _shopperFirstName: string;
  private _shopperLastName: string;
  private _shopperPhone: string;
  private _shopperEmail: string;
  private _billingAddress?: CreatePaymentSessionRequest.Address;
  private _shippingAddress?: CreatePaymentSessionRequest.Address;
  private _paymentMethodBrand: string;
  private _paymentMethodData: string;
  private _browserInfo: string;
  private _origin: string;
  private _countryCode: string;
  private _paymentMethodVariant: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreatePaymentSessionRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreatePaymentSessionRequest.AsObject>) {
    _value = _value || {};
    this.linkId = _value.linkId;
    this.shopperFirstName = _value.shopperFirstName;
    this.shopperLastName = _value.shopperLastName;
    this.shopperPhone = _value.shopperPhone;
    this.shopperEmail = _value.shopperEmail;
    this.billingAddress = _value.billingAddress
      ? new CreatePaymentSessionRequest.Address(_value.billingAddress)
      : undefined;
    this.shippingAddress = _value.shippingAddress
      ? new CreatePaymentSessionRequest.Address(_value.shippingAddress)
      : undefined;
    this.paymentMethodBrand = _value.paymentMethodBrand;
    this.paymentMethodData = _value.paymentMethodData;
    this.browserInfo = _value.browserInfo;
    this.origin = _value.origin;
    this.countryCode = _value.countryCode;
    this.paymentMethodVariant = _value.paymentMethodVariant;
    CreatePaymentSessionRequest.refineValues(this);
  }
  get linkId(): string {
    return this._linkId;
  }
  set linkId(value: string) {
    this._linkId = value;
  }
  get shopperFirstName(): string {
    return this._shopperFirstName;
  }
  set shopperFirstName(value: string) {
    this._shopperFirstName = value;
  }
  get shopperLastName(): string {
    return this._shopperLastName;
  }
  set shopperLastName(value: string) {
    this._shopperLastName = value;
  }
  get shopperPhone(): string {
    return this._shopperPhone;
  }
  set shopperPhone(value: string) {
    this._shopperPhone = value;
  }
  get shopperEmail(): string {
    return this._shopperEmail;
  }
  set shopperEmail(value: string) {
    this._shopperEmail = value;
  }
  get billingAddress(): CreatePaymentSessionRequest.Address | undefined {
    return this._billingAddress;
  }
  set billingAddress(value: CreatePaymentSessionRequest.Address | undefined) {
    this._billingAddress = value;
  }
  get shippingAddress(): CreatePaymentSessionRequest.Address | undefined {
    return this._shippingAddress;
  }
  set shippingAddress(value: CreatePaymentSessionRequest.Address | undefined) {
    this._shippingAddress = value;
  }
  get paymentMethodBrand(): string {
    return this._paymentMethodBrand;
  }
  set paymentMethodBrand(value: string) {
    this._paymentMethodBrand = value;
  }
  get paymentMethodData(): string {
    return this._paymentMethodData;
  }
  set paymentMethodData(value: string) {
    this._paymentMethodData = value;
  }
  get browserInfo(): string {
    return this._browserInfo;
  }
  set browserInfo(value: string) {
    this._browserInfo = value;
  }
  get origin(): string {
    return this._origin;
  }
  set origin(value: string) {
    this._origin = value;
  }
  get countryCode(): string {
    return this._countryCode;
  }
  set countryCode(value: string) {
    this._countryCode = value;
  }
  get paymentMethodVariant(): string {
    return this._paymentMethodVariant;
  }
  set paymentMethodVariant(value: string) {
    this._paymentMethodVariant = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreatePaymentSessionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreatePaymentSessionRequest.AsObject {
    return {
      linkId: this.linkId,
      shopperFirstName: this.shopperFirstName,
      shopperLastName: this.shopperLastName,
      shopperPhone: this.shopperPhone,
      shopperEmail: this.shopperEmail,
      billingAddress: this.billingAddress
        ? this.billingAddress.toObject()
        : undefined,
      shippingAddress: this.shippingAddress
        ? this.shippingAddress.toObject()
        : undefined,
      paymentMethodBrand: this.paymentMethodBrand,
      paymentMethodData: this.paymentMethodData,
      browserInfo: this.browserInfo,
      origin: this.origin,
      countryCode: this.countryCode,
      paymentMethodVariant: this.paymentMethodVariant
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreatePaymentSessionRequest.AsProtobufJSON {
    return {
      linkId: this.linkId,
      shopperFirstName: this.shopperFirstName,
      shopperLastName: this.shopperLastName,
      shopperPhone: this.shopperPhone,
      shopperEmail: this.shopperEmail,
      billingAddress: this.billingAddress
        ? this.billingAddress.toProtobufJSON(options)
        : null,
      shippingAddress: this.shippingAddress
        ? this.shippingAddress.toProtobufJSON(options)
        : null,
      paymentMethodBrand: this.paymentMethodBrand,
      paymentMethodData: this.paymentMethodData,
      browserInfo: this.browserInfo,
      origin: this.origin,
      countryCode: this.countryCode,
      paymentMethodVariant: this.paymentMethodVariant
    };
  }
}
export module CreatePaymentSessionRequest {
  /**
   * Standard JavaScript object representation for CreatePaymentSessionRequest
   */
  export interface AsObject {
    linkId: string;
    shopperFirstName: string;
    shopperLastName: string;
    shopperPhone: string;
    shopperEmail: string;
    billingAddress?: CreatePaymentSessionRequest.Address.AsObject;
    shippingAddress?: CreatePaymentSessionRequest.Address.AsObject;
    paymentMethodBrand: string;
    paymentMethodData: string;
    browserInfo: string;
    origin: string;
    countryCode: string;
    paymentMethodVariant: string;
  }

  /**
   * Protobuf JSON representation for CreatePaymentSessionRequest
   */
  export interface AsProtobufJSON {
    linkId: string;
    shopperFirstName: string;
    shopperLastName: string;
    shopperPhone: string;
    shopperEmail: string;
    billingAddress: CreatePaymentSessionRequest.Address.AsProtobufJSON | null;
    shippingAddress: CreatePaymentSessionRequest.Address.AsProtobufJSON | null;
    paymentMethodBrand: string;
    paymentMethodData: string;
    browserInfo: string;
    origin: string;
    countryCode: string;
    paymentMethodVariant: string;
  }

  /**
   * Message implementation for com.kodypay.grpc.wombat.v1.CreatePaymentSessionRequest.Address
   */
  export class Address implements GrpcMessage {
    static id =
      'com.kodypay.grpc.wombat.v1.CreatePaymentSessionRequest.Address';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Address();
      Address.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Address) {
      _instance.houseNumberOrName = _instance.houseNumberOrName || '';
      _instance.street = _instance.street || '';
      _instance.city = _instance.city || '';
      _instance.postcode = _instance.postcode || '';
      _instance.country = _instance.country || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Address,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.houseNumberOrName = _reader.readString();
            break;
          case 2:
            _instance.street = _reader.readString();
            break;
          case 3:
            _instance.city = _reader.readString();
            break;
          case 4:
            _instance.postcode = _reader.readString();
            break;
          case 5:
            _instance.country = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Address.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Address, _writer: BinaryWriter) {
      if (_instance.houseNumberOrName) {
        _writer.writeString(1, _instance.houseNumberOrName);
      }
      if (_instance.street) {
        _writer.writeString(2, _instance.street);
      }
      if (_instance.city) {
        _writer.writeString(3, _instance.city);
      }
      if (_instance.postcode) {
        _writer.writeString(4, _instance.postcode);
      }
      if (_instance.country) {
        _writer.writeString(5, _instance.country);
      }
    }

    private _houseNumberOrName: string;
    private _street: string;
    private _city: string;
    private _postcode: string;
    private _country: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Address to deeply clone from
     */
    constructor(_value?: RecursivePartial<Address.AsObject>) {
      _value = _value || {};
      this.houseNumberOrName = _value.houseNumberOrName;
      this.street = _value.street;
      this.city = _value.city;
      this.postcode = _value.postcode;
      this.country = _value.country;
      Address.refineValues(this);
    }
    get houseNumberOrName(): string {
      return this._houseNumberOrName;
    }
    set houseNumberOrName(value: string) {
      this._houseNumberOrName = value;
    }
    get street(): string {
      return this._street;
    }
    set street(value: string) {
      this._street = value;
    }
    get city(): string {
      return this._city;
    }
    set city(value: string) {
      this._city = value;
    }
    get postcode(): string {
      return this._postcode;
    }
    set postcode(value: string) {
      this._postcode = value;
    }
    get country(): string {
      return this._country;
    }
    set country(value: string) {
      this._country = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Address.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Address.AsObject {
      return {
        houseNumberOrName: this.houseNumberOrName,
        street: this.street,
        city: this.city,
        postcode: this.postcode,
        country: this.country
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Address.AsProtobufJSON {
      return {
        houseNumberOrName: this.houseNumberOrName,
        street: this.street,
        city: this.city,
        postcode: this.postcode,
        country: this.country
      };
    }
  }
  export module Address {
    /**
     * Standard JavaScript object representation for Address
     */
    export interface AsObject {
      houseNumberOrName: string;
      street: string;
      city: string;
      postcode: string;
      country: string;
    }

    /**
     * Protobuf JSON representation for Address
     */
    export interface AsProtobufJSON {
      houseNumberOrName: string;
      street: string;
      city: string;
      postcode: string;
      country: string;
    }
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.CreatePaymentSessionResponse
 */
export class CreatePaymentSessionResponse implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.CreatePaymentSessionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreatePaymentSessionResponse();
    CreatePaymentSessionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreatePaymentSessionResponse) {
    _instance.paymentResponse = _instance.paymentResponse || '';
    _instance.expired = _instance.expired || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreatePaymentSessionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.paymentResponse = _reader.readString();
          break;
        case 2:
          _instance.expired = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CreatePaymentSessionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreatePaymentSessionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.paymentResponse) {
      _writer.writeString(1, _instance.paymentResponse);
    }
    if (_instance.expired) {
      _writer.writeBool(2, _instance.expired);
    }
  }

  private _paymentResponse: string;
  private _expired: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreatePaymentSessionResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CreatePaymentSessionResponse.AsObject>
  ) {
    _value = _value || {};
    this.paymentResponse = _value.paymentResponse;
    this.expired = _value.expired;
    CreatePaymentSessionResponse.refineValues(this);
  }
  get paymentResponse(): string {
    return this._paymentResponse;
  }
  set paymentResponse(value: string) {
    this._paymentResponse = value;
  }
  get expired(): boolean {
    return this._expired;
  }
  set expired(value: boolean) {
    this._expired = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreatePaymentSessionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreatePaymentSessionResponse.AsObject {
    return {
      paymentResponse: this.paymentResponse,
      expired: this.expired
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreatePaymentSessionResponse.AsProtobufJSON {
    return {
      paymentResponse: this.paymentResponse,
      expired: this.expired
    };
  }
}
export module CreatePaymentSessionResponse {
  /**
   * Standard JavaScript object representation for CreatePaymentSessionResponse
   */
  export interface AsObject {
    paymentResponse: string;
    expired: boolean;
  }

  /**
   * Protobuf JSON representation for CreatePaymentSessionResponse
   */
  export interface AsProtobufJSON {
    paymentResponse: string;
    expired: boolean;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.CompletePaymentSessionRequest
 */
export class CompletePaymentSessionRequest implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.CompletePaymentSessionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CompletePaymentSessionRequest();
    CompletePaymentSessionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CompletePaymentSessionRequest) {
    _instance.linkId = _instance.linkId || '';
    _instance.details = _instance.details || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CompletePaymentSessionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.linkId = _reader.readString();
          break;
        case 2:
          _instance.details = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CompletePaymentSessionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CompletePaymentSessionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.linkId) {
      _writer.writeString(1, _instance.linkId);
    }
    if (_instance.details) {
      _writer.writeString(2, _instance.details);
    }
  }

  private _linkId: string;
  private _details: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CompletePaymentSessionRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CompletePaymentSessionRequest.AsObject>
  ) {
    _value = _value || {};
    this.linkId = _value.linkId;
    this.details = _value.details;
    CompletePaymentSessionRequest.refineValues(this);
  }
  get linkId(): string {
    return this._linkId;
  }
  set linkId(value: string) {
    this._linkId = value;
  }
  get details(): string {
    return this._details;
  }
  set details(value: string) {
    this._details = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CompletePaymentSessionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CompletePaymentSessionRequest.AsObject {
    return {
      linkId: this.linkId,
      details: this.details
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CompletePaymentSessionRequest.AsProtobufJSON {
    return {
      linkId: this.linkId,
      details: this.details
    };
  }
}
export module CompletePaymentSessionRequest {
  /**
   * Standard JavaScript object representation for CompletePaymentSessionRequest
   */
  export interface AsObject {
    linkId: string;
    details: string;
  }

  /**
   * Protobuf JSON representation for CompletePaymentSessionRequest
   */
  export interface AsProtobufJSON {
    linkId: string;
    details: string;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.CompletePaymentSessionResponse
 */
export class CompletePaymentSessionResponse implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.CompletePaymentSessionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CompletePaymentSessionResponse();
    CompletePaymentSessionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CompletePaymentSessionResponse) {
    _instance.paymentResponse = _instance.paymentResponse || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CompletePaymentSessionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.paymentResponse = _reader.readString();
          break;
        case 2:
          _instance.successUrl = _reader.readString();
          break;
        case 3:
          _instance.failureUrl = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CompletePaymentSessionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CompletePaymentSessionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.paymentResponse) {
      _writer.writeString(1, _instance.paymentResponse);
    }
    if (_instance.successUrl !== undefined && _instance.successUrl !== null) {
      _writer.writeString(2, _instance.successUrl);
    }
    if (_instance.failureUrl !== undefined && _instance.failureUrl !== null) {
      _writer.writeString(3, _instance.failureUrl);
    }
  }

  private _paymentResponse: string;
  private _successUrl?: string;
  private _failureUrl?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CompletePaymentSessionResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CompletePaymentSessionResponse.AsObject>
  ) {
    _value = _value || {};
    this.paymentResponse = _value.paymentResponse;
    this.successUrl = _value.successUrl;
    this.failureUrl = _value.failureUrl;
    CompletePaymentSessionResponse.refineValues(this);
  }
  get paymentResponse(): string {
    return this._paymentResponse;
  }
  set paymentResponse(value: string) {
    this._paymentResponse = value;
  }
  get successUrl(): string | undefined {
    return this._successUrl;
  }
  set successUrl(value?: string) {
    this._successUrl = value;
  }
  get failureUrl(): string | undefined {
    return this._failureUrl;
  }
  set failureUrl(value?: string) {
    this._failureUrl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CompletePaymentSessionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CompletePaymentSessionResponse.AsObject {
    return {
      paymentResponse: this.paymentResponse,
      successUrl: this.successUrl,
      failureUrl: this.failureUrl
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CompletePaymentSessionResponse.AsProtobufJSON {
    return {
      paymentResponse: this.paymentResponse,
      successUrl:
        this.successUrl === null || this.successUrl === undefined
          ? null
          : this.successUrl,
      failureUrl:
        this.failureUrl === null || this.failureUrl === undefined
          ? null
          : this.failureUrl
    };
  }
}
export module CompletePaymentSessionResponse {
  /**
   * Standard JavaScript object representation for CompletePaymentSessionResponse
   */
  export interface AsObject {
    paymentResponse: string;
    successUrl?: string;
    failureUrl?: string;
  }

  /**
   * Protobuf JSON representation for CompletePaymentSessionResponse
   */
  export interface AsProtobufJSON {
    paymentResponse: string;
    successUrl: string | null;
    failureUrl: string | null;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.CreateApplePayRequest
 */
export class CreateApplePayRequest implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.CreateApplePayRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateApplePayRequest();
    CreateApplePayRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateApplePayRequest) {
    _instance.linkId = _instance.linkId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateApplePayRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.linkId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateApplePayRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateApplePayRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.linkId) {
      _writer.writeString(1, _instance.linkId);
    }
  }

  private _linkId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateApplePayRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateApplePayRequest.AsObject>) {
    _value = _value || {};
    this.linkId = _value.linkId;
    CreateApplePayRequest.refineValues(this);
  }
  get linkId(): string {
    return this._linkId;
  }
  set linkId(value: string) {
    this._linkId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateApplePayRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateApplePayRequest.AsObject {
    return {
      linkId: this.linkId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateApplePayRequest.AsProtobufJSON {
    return {
      linkId: this.linkId
    };
  }
}
export module CreateApplePayRequest {
  /**
   * Standard JavaScript object representation for CreateApplePayRequest
   */
  export interface AsObject {
    linkId: string;
  }

  /**
   * Protobuf JSON representation for CreateApplePayRequest
   */
  export interface AsProtobufJSON {
    linkId: string;
  }
}

/**
 * Message implementation for com.kodypay.grpc.wombat.v1.CreateApplePayResponse
 */
export class CreateApplePayResponse implements GrpcMessage {
  static id = 'com.kodypay.grpc.wombat.v1.CreateApplePayResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateApplePayResponse();
    CreateApplePayResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateApplePayResponse) {
    _instance.responseData = _instance.responseData || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateApplePayResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.responseData = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateApplePayResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateApplePayResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.responseData) {
      _writer.writeString(1, _instance.responseData);
    }
  }

  private _responseData: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateApplePayResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateApplePayResponse.AsObject>) {
    _value = _value || {};
    this.responseData = _value.responseData;
    CreateApplePayResponse.refineValues(this);
  }
  get responseData(): string {
    return this._responseData;
  }
  set responseData(value: string) {
    this._responseData = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateApplePayResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateApplePayResponse.AsObject {
    return {
      responseData: this.responseData
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateApplePayResponse.AsProtobufJSON {
    return {
      responseData: this.responseData
    };
  }
}
export module CreateApplePayResponse {
  /**
   * Standard JavaScript object representation for CreateApplePayResponse
   */
  export interface AsObject {
    responseData: string;
  }

  /**
   * Protobuf JSON representation for CreateApplePayResponse
   */
  export interface AsProtobufJSON {
    responseData: string;
  }
}
