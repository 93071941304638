import { createActionGroup, props } from '@ngrx/store';
import { ShopperInformation } from '../../types/shopper-information.type';

export const paymentActions = createActionGroup({
  source: 'Payment',
  events: {
    'Open link': props<{ linkId: string }>(),
    'Submit shopper information': props<{ linkId: string; shopperInformation: Partial<ShopperInformation> }>(),
  },
});
