<div #hook></div>
@if (!loaded) {
  <div class="loader">
    <kp-skeleton-loader [theme]="{ height: '3rem' }"></kp-skeleton-loader>
    <br />
    <kp-skeleton-loader [theme]="{ height: '17rem' }"></kp-skeleton-loader>
    <br />
    <kp-skeleton-loader [theme]="{ height: '3rem' }"></kp-skeleton-loader>
  </div>
}
