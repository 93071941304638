import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PaymentFacadeService } from '../../+state/services/payment-facade.service';

@Injectable({
  providedIn: 'root',
})
export class LinkShopperInformationRequiredGuard  {
  constructor(private paymentFacadeService: PaymentFacadeService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.paymentFacadeService.shopperInformation$().pipe(
      map((shopperInformation) => {
        if (shopperInformation) {
          return true;
        } else {
          // Redirect the user to fill out their information
          const linkId = route.paramMap.get('linkId');
          return this.router.createUrlTree([`${linkId}/info`]);
        }
      })
    );
  }
}
