import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { paymentActions } from '../actions/payment.actions';
import { asyncScheduler, catchError, map, switchMap } from 'rxjs';
import { paymentApiActions } from '../actions/payment-api.actions';
import { LinkApiService } from '../../services/link-api/link-api.service';
import { featureFlagsActions } from '@libs/utility-feature-flags/+state/actions/feature-flags.actions';
import { retryWithDelay } from '@libs/shared/utilities/grpc/retry-with-delay.operator';

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions,
    private apiService: LinkApiService,
  ) {}

  fetchLinkDetails$ = createEffect(
    () =>
      ({ scheduler = asyncScheduler, interval = 1000, times = 20 } = {}) =>
        this.actions$.pipe(
          ofType(paymentActions.openLink),
          switchMap(({ linkId }) =>
            this.apiService.getLinkDetails(linkId).pipe(
              retryWithDelay(times, interval, this.actions$, scheduler),
              map((linkDetails) => paymentApiActions.linkDetailsLoadedSuccess({ linkDetails })),
              catchError((error) => [paymentApiActions.linkDetailsLoadedError({ error })]),
            ),
          ),
        ),
  );

  listenToFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.openLink),
      map(() => featureFlagsActions.initialize({ context: { anonymous: true } })),
    ),
  );
}
