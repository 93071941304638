import { Inject, Injectable } from '@angular/core';
import { ADYEN_CLIENT_KEY } from '../../tokens/adyen-client-key.token';
import { AdyenEnvironment } from '../../types/adyen-environment.type';
import { ADYEN_ENVIRONMENT } from '../../tokens/adyen-environment.token';
import type Core from '@adyen/adyen-web/dist/types/core';
import type { PaymentResult } from '../../types/payment-result.type';
import { AdyenPaymentApiService } from '../../interfaces/adyen-payment-api-service.interface';
import type { PaymentMethodsResponse } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';
import type { AdyenPaymentResponse, AdyenPaymentResult } from '../../types/adyen-payment-response.interface';
import type { PaymentMethod } from '@adyen/adyen-web/dist/types/types';
import { GOOGLE_PAY_CONFIG } from '../../tokens/google-pay-config.token';
import { GooglePayConfig } from '../../types/google-pay-config.type';
import type UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdyenPaymentService {
  public readonly style: string = 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/5.50.1/adyen.css';

  constructor(
    @Inject(ADYEN_CLIENT_KEY) private clientKey: string,
    @Inject(ADYEN_ENVIRONMENT) private environment: AdyenEnvironment,
    @Inject(GOOGLE_PAY_CONFIG) private googlePayConfig: GooglePayConfig,
    private paymentApiService: AdyenPaymentApiService,
  ) {}

  async initiatePayment(
    currency: string,
    cents: number,
    locale: string,
    until$: Subject<any>,
  ): Promise<{
    checkout: Core;
    paymentResult: Promise<PaymentResult>;
  }> {
    let resolveResult: (value: PaymentResult) => void;
    const paymentResult = new Promise<PaymentResult>((res) => (resolveResult = res));

    const checkout = await this.createAdyenCheckout(
      await this.paymentApiService.getPaymentMethods(until$),
      currency,
      locale,
      cents,
      until$,
      (result) => {
        if (result.resultCode === 'Authorised') {
          resolveResult({
            outcome: 'success',
          });
        } else {
          resolveResult({
            outcome: 'failed',
            failureReason: result.refusalReason,
          });
        }
      },
      (error) =>
        resolveResult({
          outcome: 'failed',
          failureReason: error.message,
        }),
    );

    return {
      checkout,
      paymentResult,
    };
  }

  async handleRedirect(redirectResult: string): Promise<PaymentResult> {
    const decoded = decodeURIComponent(redirectResult);
    const details = {
      redirectResult: decoded,
    };

    try {
      const response = await this.paymentApiService.submitAdditionalDetails({ details });

      if ('action' in response) {
        throw new Error('Unexpected action'); // An action is not expected at this point
      }

      if (response.resultCode === 'Authorised') {
        return {
          outcome: 'success',
        };
      }

      throw new Error(response.refusalReason);
    } catch (error: unknown) {
      const failureReason = error instanceof Error ? error.message : undefined;

      return {
        outcome: 'failed',
        failureReason,
      };
    }
  }

  private async createAdyenCheckout(
    paymentMethodsResponse: PaymentMethodsResponse,
    currency: string,
    locale: string,
    cents: number,
    until$: Subject<any>,
    onPaymentResult: (result: AdyenPaymentResult) => void,
    onError: (error: Error) => void,
  ): Promise<Core> {
    const adyenCheckout = (await import('@adyen/adyen-web')).default;
    const merchantDisplayName = await this.paymentApiService.merchantDisplayName();
    const gatewayMerchantId = await this.paymentApiService.gatewayMerchantId();

    return adyenCheckout({
      paymentMethodsResponse,
      clientKey: this.clientKey,
      environment: this.environment,
      amount: {
        currency,
        value: cents,
      },
      locale,
      onSubmit: (state, dropin) => {
        const brand = this.determineBrand(state.data.paymentMethod);

        this.handlePaymentResponse(
          this.paymentApiService.makePayment(state.data.paymentMethod, state.data.browserInfo, brand),
          dropin,
          onPaymentResult,
          onError,
        );
      },
      onAdditionalDetails: (state, dropin) => {
        this.handlePaymentResponse(this.paymentApiService.submitAdditionalDetails(state.data), dropin, onPaymentResult, onError);
      },
      onError: (error) => onError(error),
      analytics: {
        enabled: true,
      },
      paymentMethodsConfiguration: {
        alipay_hk: {
          name: 'Alipay HK',
        },
        alipay: {
          name: 'Alipay',
        },
        googlepay: {
          environment: this.googlePayConfig.environment,
          countryCode: this.googlePayConfig.countryCode,
          buttonType: 'pay',
          onError: (error) => {
            // A GPay 'cancel' isn't an error, they just closed the dialog
            if (error.name === 'CANCEL') {
              return;
            }
            onError(error);
          },
          configuration: {
            merchantName: merchantDisplayName,
            gatewayMerchantId: gatewayMerchantId || this.googlePayConfig.gatewayMerchantId,
            merchantId: this.googlePayConfig.merchantId,
          },
        },
        applepay: {
          onValidateMerchant: (resolve: (session: unknown) => void, reject: (error: unknown) => void) => {
            this.paymentApiService.applePayValidateMerchant().then(resolve).catch(reject);
          },
          configuration: {
            merchantName: merchantDisplayName,
          },
          onError: (error) => {
            if (error.name === 'CANCEL') {
              return;
            }
            onError(error);
          },
        },
      },
    });
  }

  private determineBrand(paymentMethod: PaymentMethod): string | undefined {
    if (paymentMethod.type === 'scheme') {
      return paymentMethod.brand;
    }
    if (paymentMethod.type === 'googlepay') {
      // Bad adyen typings
      return 'googlePayCardNetwork' in paymentMethod ? (paymentMethod as any).googlePayCardNetwork : undefined;
    }
    return undefined;
  }

  private async handlePaymentResponse(
    response: Promise<AdyenPaymentResponse>,
    dropin: UIElement | undefined,
    onPaymentResult: (result: AdyenPaymentResult) => void,
    onError: (error: Error) => void,
  ) {
    try {
      const result = await response;
      if ('action' in result) {
        dropin?.handleAction(result.action);
      } else {
        onPaymentResult(result);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        onError(error);
      } else {
        onError(new Error(`Unexpected error: ${JSON.stringify(error)}`));
      }
    }
  }
}
