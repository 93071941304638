import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { paymentFeature } from '../payment.feature';
import { LinkDetails } from '../../types/link-details.type';
import { ShopperInformation } from '../../types/shopper-information.type';

@Injectable({
  providedIn: 'root',
})
export class PaymentFacadeService {
  constructor(private store: Store) {}

  linkId$(): Observable<string | undefined> {
    return this.store.select(paymentFeature.selectLinkId);
  }

  linkDetails$(): Observable<LinkDetails | undefined> {
    return this.store.select(paymentFeature.selectLinkDetails);
  }

  error$(): Observable<string | undefined> {
    return this.store.select(paymentFeature.selectError);
  }

  shopperInformation$(): Observable<Partial<ShopperInformation> | undefined> {
    return this.store.select(paymentFeature.selectShopperInformation);
  }

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}
