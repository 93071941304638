import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private renderer = this.rendererFactory.createRenderer(null, null);

  constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {}

  injectStyle(src: string): HTMLElement {
    const element = this.renderer.createElement('link');
    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('href', src);
    element.setAttribute('type', 'text/css');

    this.renderer.appendChild(this.document.head, element);
    return element;
  }

  remove(element: HTMLElement): void {
    this.renderer.removeChild(this.document.head, element);
  }
}
